import {
  AvailableGastroProductKind,
  GastroGenericProductDetails,
  GastroProduct,
  GastroProductData,
  GastroProductKind,
} from '@modules/gastro-generic/models'
import { getGastroCouponData } from '@modules/gastro-generic/kinds-data/gastro-coupon-kind-data'
import { getGastroCardBoostData } from '@modules/gastro-generic/kinds-data/gastro-coupon-boost-kind-data'
import { getGastroCard2024Data } from '@modules/gastro-generic/kinds-data/gastro-card-2024-kind-data'
import { getGastroCard2025Data } from '@modules/gastro-generic/kinds-data/gastro-card-2025-kind-data'
import { getGastroCardDynamicData } from '@modules/gastro-generic/kinds-data/gastro-card-dynamic-kind-data'
import { getGastroCard2026Data } from '@modules/gastro-generic/kinds-data/gastro-card-2026-kind-data'
import { getGastroCard2027Data } from '@modules/gastro-generic/kinds-data/gastro-card-2027-kind-data'
import { getGastroCard2026OctoberData } from '@modules/gastro-generic/kinds-data/gastro-card-2026-october-kind-data'

export const getProductData = (
  productDetails: GastroGenericProductDetails | undefined,
  gastroProduct: GastroProduct,
): GastroProductData => {
  const emptyProductData = {
    alertText: '',
    labelImageSrc: '',
    productName: '',
    productHeader: '',
    benefitsPoints: [],
    benefitsList: [],
  }
  if (!productDetails) {
    return emptyProductData
  }

  const data: Record<GastroProductKind, GastroProductData> = {
    gastro_coupon: getGastroCouponData(productDetails, gastroProduct),
    gastro_card_boost: getGastroCardBoostData(productDetails, gastroProduct),
    normal: emptyProductData,
    gastro_card_2024: getGastroCard2024Data(productDetails, gastroProduct),
    gastro_card_2025: getGastroCard2025Data(productDetails, gastroProduct),
    gastro_card_2026: getGastroCard2026Data(gastroProduct),
    gastro_card_2026_october: getGastroCard2026OctoberData(gastroProduct),
    gastro_card_2027: getGastroCard2027Data(productDetails, gastroProduct),
    gastro_card_2024_wholesale: getGastroCard2024Data(productDetails, gastroProduct),
    gastro_card_2025_wholesale: getGastroCard2025Data(productDetails, gastroProduct),
    gastro_card_dynamic: getGastroCardDynamicData(productDetails, gastroProduct),
  }

  return data[gastroProduct.kind]
}

export const isGastroGenericProduct = (kind?: GastroProductKind): boolean => {
  if (!kind) return false

  return AvailableGastroProductKind.includes(kind as GastroProductKind)
}
