import * as React from 'react'
import { SelectedPlanBanner } from '@modules/holiday-benefit/employee-invitation/selected-plan-banner'
import { HolidayBenefitInvitationRulesSection } from '@modules/holiday-benefit/employee-invitation/rules'
import { useHolidayBenefitInvitationSelectedPlan } from '@modules/holiday-benefit/employee-invitation/use-holiday-benefit-invitation-selected-plan'
import clsx from 'clsx'
import { HolidayBenefitInvitation } from '@modules/holiday-benefit/employee-invitation/models'
import { FadeInView } from '@components/fade-in-view'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'

interface Props {
  onPreviousStepClick: () => void
  onSubmit: () => Promise<void>
  benefitInvitation: HolidayBenefitInvitation
}

export const HolidayBenefitInvitationAccountLoggedIn = ({
  onPreviousStepClick,
  onSubmit,
  benefitInvitation,
}: Props): React.ReactNode => {
  const { user } = useSelector(selectAppData)
  const { color } = useHolidayBenefitInvitationSelectedPlan()

  return (
    <FadeInView>
      <div className="my-3 bg-light-blue rounded-3 p-lg-5 p-3">
        <h5 className={clsx('font-xxl fw-bold text-center', color)}>Witaj {user?.first_name}!</h5>
        <p className="text-center my-4 pb-0 font-lg mx-auto col-lg-9 col-12 text-darker-gray">
          Benefit zostanie dodany do Twojego Konta Gościa w systemie
          <span className="text-nowrap"> Holiday Park & Resort</span>.
        </p>

        <SelectedPlanBanner onEditClick={onPreviousStepClick} benefitInvitation={benefitInvitation} />

        <div className="mt-5">
          <DataRow label="Imię i nazwisko" value={`${user?.first_name} ${user?.last_name}`} />
          <DataRow label="Adres e-mail" value={user?.email} />
          <DataRow label="Numer telefonu" value={user?.phone} />
        </div>
      </div>

      <HolidayBenefitInvitationRulesSection onSubmit={onSubmit} benefitInvitation={benefitInvitation} />
    </FadeInView>
  )
}

const DataRow = ({ label, value }) => (
  <div className="row text-darker-gray mb-1">
    <div className="col-4">{label}</div>
    <div className="col-8 fw-bold">{value}</div>
  </div>
)
