import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ExpandableText } from '@components/controls/expandable-text'
import { useModal } from '@components/modals/use-modal'
import { useRules } from '@hooks/use-rules'

interface Props {
  onScrollToResorts: () => void
}

export const EndlessHolidayDetailsContentDescription = ({ onScrollToResorts }: Props): React.ReactNode => {
  const [showDescriptionModal] = useModal('EndlessHolidayDescriptionModal')

  const { getRules } = useRules()

  const productRules = getRules(['product_package_endless_holiday_rules'])

  return (
    <>
      <button className="btn-unstyled" onClick={showDescriptionModal} type="button">
        <IconWithText
          text="Czym są Wakacje w nieskończoność?"
          iconClassName="uil-info-circle font-xxl me-2"
          textClassName="font-500 font-lg"
          className="text-secondary"
        />
      </button>
      <div className="mt-2 col-lg-9 col-12">
        <p>
          Kup voucher na pobyt w latach od 2025*, 2026, 2027, 2028 lub 2029 i korzystaj z najniższych cen. Data ważności
          każdego vouchera pobytowego to <strong>5 lat</strong>, licząc od wybranego roku. Przykładowo: Voucher od roku
          2025 będzie ważny do końca 2029.
        </p>

        <p>
          W ramach oferty nabywasz voucher pobytowy do realizacji w wybranym roku oraz voucher gastronomiczny do
          wykorzystania we wszystkich dostępnych punktach (włącznie z Restauracją), w ciągu 3 lat od daty ważności kodu
          pobytowego.
        </p>

        <ExpandableText
          wrapperClassName="font-lg"
          text={
            <section>
              <p>
                Przykładowo: Kupując voucher na pobyt w roku 2027, Twój voucher gastronomiczny będzie ważny do końca
                2029 roku, do użycia na różnych rezerwacjach.
              </p>
              <div>
                Terminy wykorzystania voucherów pobytowych różnią się w zależności od wariantu Pakietu:
                <ul className="list-unstyled mb-0 mt-1">
                  <li>
                    <strong>Pakiet Brązowy</strong> - możesz do nas przyjechać od 01.01 do 31.05 oraz od 01.10 do 31.12*
                  </li>
                  <li>
                    <strong>Pakiet Srebrny</strong> - możesz do nas przyjechać od 01.01 do 30.06 oraz od 01.09 do 31.12*
                  </li>
                  <li>
                    <strong>Pakiet Złoty</strong> - możesz do nas przyjechać od 01.01 do 07.07 oraz od 26.08 do 31.12
                  </li>
                </ul>
                <span className="font-sm">*Z wyłączeniem terminów specjalnych.</span>
              </div>
              <p className="mt-2">
                Do każdego vouchera pobytowego zostanie doliczona kwota zabezpieczenia na poczet kaucji zwrotnej w
                wysokości <span className="text-nowrap">1 000 zł</span>, płatna od razu przy zakupie oferty.
              </p>
              <div>
                Rezerwacji dokonuje się samodzielnie poprzez stronę{' '}
                <a
                  href="https://rezerwuj.holidaypark.pl/"
                  className="fw-bold text-decoration-none text-darker-gray"
                  target="_blank"
                >
                  rezerwuj.holidaypark.pl
                </a>{' '}
                lub telefonicznie z pomocą Biura Obsługi Klienta pod numerem <strong>735 200 800</strong>. Przy
                zakładaniu rezerwacji należy opłacić jednorazową usługę przygotowania lokalu. Do każdej rezerwacji
                zostanie doliczona opłata eksploatacyjna i opłata klimatyczna, płatne podczas meldowania. Przy wyjeździe
                należy uiścić opłatę z tytułu zużytych mediów. Wysokość opłat została wskazana w Cenniku oraz w
                Regulaminie Rezerwacji Holiday Park & Resort.
              </div>
            </section>
          }
          initialHeight={0}
        />
      </div>

      <div className="d-flex mt-3 gap-lg-5 gap-3 flex-column flex-lg-row">
        <a href={productRules[0]?.url} className="text-decoration-none text-darker-gray cursor-pointer" target="_blank">
          <IconWithText
            text="Regulamin akcji Wakacje w nieskończoność"
            iconClassName="uil-file-check-alt font-xl me-2"
            textClassName="font-500 font-lg"
          />
        </a>
        <button className="btn-unstyled" onClick={onScrollToResorts} type="button">
          <IconWithText
            text="Gdzie wykorzystasz swój pakiet?"
            iconClassName="uil-map-marker font-xl me-2"
            textClassName="font-500 font-lg"
          />
        </button>
      </div>
    </>
  )
}
